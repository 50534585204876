import React from 'react'

import Team from '../components/team'
import Seo from '../components/seo'
import Layout from '../components/layout'

const TeamPage = () => {
  return (
    <Layout pageKey='team'>
      <Seo title={'Team'} />
      <Team />
    </Layout>
  )
}

export default TeamPage
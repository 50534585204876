import React, { useState } from "react"
import { Card, CardMedia, CardContent } from "@mui/material"
import { styled } from "@mui/system"
import Grid from "@mui/material/Unstable_Grid2"
import GitHubIcon from "@mui/icons-material/GitHub"
import { FaXTwitter } from "react-icons/fa6"

import Ebru from "../images/team/ebru.png"
import Emre from "../images/team/emre.png"
import Olha from "../images/team/olha.png"
import Furkan from "../images/team/furkan.png"

const Team = () => {
  const [showMoreProjects, setShowMoreProjects] = useState({})

  const team = [
    {
      name: `Ebru`,
      image: Ebru,
      role: "Developer",
      social: [
        {
          name: `twitter`,
          url: `https://x.com/ebrukye`,
        },
        {
          name: `github`,
          url: `https://github.com/ebru`,
        },
      ],
    },
    {
      name: `Emre`,
      image: Emre,
      role: `Business`,
      social: [
        {
          name: `github`,
          url: `https://github.com/emrekya021`,
        },
      ],
    },
    {
      name: `Olha`,
      image: Olha,
      role: "Developer",
      social: [
        {
          name: `twitter`,
          url: `https://x.com/OlhaPyshchyk`,
        },
        {
          name: `github`,
          url: `https://github.com/pyshchyk-o`,
        },
      ],
    },
    {
      name: `Furkan`,
      image: Furkan,
      role: "Developer",
      social: [
        {
          name: `github`,
          url: `https://github.com/foksuz44`,
        },
      ],
    }
  ];

  const getSocialIcon = name => {
    if (name === "github") {
      return <GitHubIcon style={{ width: 20 }} />
    }
    if (name === "twitter") {
      return <FaXTwitter style={{ width: 20 }} />
    }
    return null
  }

  return (
    <div>
      <center>
        <h1 style={{ fontSize: 34, marginTop: 10 }}>Our lovely team</h1>
        <p
          style={{
            marginTop: -10,
          }}
        >
          We're indie makers who collaborate on different ideas and try to bring
          them to life.
        </p>
      </center>
      <Grid container spacing={3}>
        {team.map((member, index) => (
          <Grid key={index} xs={6} sm={6} md={3} lg={3}>
            <CardContainer
              style={{
                textAlign: "center",
              }}
            >
              <CardMedia
                component="img"
                alt={member.name}
                sx={{ height: { xs: 240, sm: 270, md: 240, lg: 200 } }}
                image={member.image}
              />
              <CardContent
                style={{
                  color: "white",
                }}
              >
                <div style={{ marginTop: -30 }}>
                  <div style={{ fontWeight: "bold" }}>{member.name}</div>
                  <div
                    style={{
                      marginTop: 2,
                      fontSize: 15,
                    }}
                  >
                    {member.role}
                  </div>
                  <div
                    style={{
                      marginTop: 5,
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "center",
                      gap: 5,
                    }}
                  >
                    {member.social.map((social, index) => (
                      <a
                        key={index}
                        href={social.url}
                        target="_blank"
                        rel="noreferrer"
                        style={{
                          color: "white",
                        }}
                      >
                        <div>{getSocialIcon(social.name)}</div>
                      </a>
                    ))}
                  </div>
                  {/* <div style={{ marginTop: 10 }}>
                    {member.projects.slice(0, showMoreProjects[member.name] ? member.projects.length : 1).map((project, index) => (
                      <div key={index} style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'center',
                        gap: 5,
                      }}>
                        <a href={project.url} target="_blank" rel="noreferrer" style={{
                          color: 'white'
                        }}>
                          <div>
                            <span style={{
                              fontSize: 14,
                              border: '1px solid #fff',
                              borderRadius: 5,
                              padding: '2px 5px',
                              background: 'rgb(0, 0, 0, 0.1)'
                            }}>
                              {project.name}
                            </span>
                          </div>
                        </a>
                        {member.projects.length > 1 && !showMoreProjects[member.name] && (
                          <div
                            onClick={() => setShowMoreProjects({ [member.name]: true })}
                            style={{
                              cursor: 'pointer'
                            }}
                          >
                            +{member.projects.length - 1}
                          </div>
                        )}
                      </div>
                    ))}
                    {showMoreProjects[member.name] && (
                      <div
                        onClick={() => setShowMoreProjects({ [member.name]: false })}
                        style={{
                          cursor: 'pointer',
                          fontSize: 14,
                          marginTop: 4,
                        }}
                      >
                        show less
                      </div>
                    )}
                  </div> */}
                </div>
              </CardContent>
            </CardContainer>
          </Grid>
        ))}
      </Grid>
      {/* <h2 style={{ textAlign: 'center', marginTop: 60, fontSize: 28, textDecoration: 'underline' }}>
        Join us
      </h2>
      <div style={{
        marginTop: -10,
        textAlign: 'center'
      }}>
        - If you're a creative who is passionate about crafting and building products in your free time <div style={{ marginTop: 20 }} />
        - Would like to collaborate on any idea, it could be yours or one of ours, <b><em>(we have many.)</em></b> <div style={{ marginTop: 20 }} />
        - You can build stuff on your own but you think that working with team is sometimes just more fun, time and budget saving <div style={{ marginTop: 20 }} />
        - Would like to work with shares on the projects from day zero and have actual ownership of what you do for a lifetime <div style={{ marginTop: 50 }} />

        Sounds interesting? We'd like to get to know you! <div style={{ marginTop: 30 }} />
        Send us an email at <span>
          <a
            href="mailto:collab@noecrafts.com?subject=[Collab] Hi, I'd like to join the team"
            style={{
              color: 'white',
              boxShadow: '#3c365c 0px -0.2rem 0px inset',
              fontWeight: 'bold',
            }}
          >
            collab[at]noecrafts.com
          </a>
        </span> <br></br> with a brief introduction and your portfolio/some previous work
      </div> */}
    </div>
  )
}

const CardContainer = styled(Card)({
  borderRadius: 10,
  minHeight: 300,
  backgroundColor: "rgb(255, 255, 255, 0.05)",
  transition: "all .1s ease-in-out",
  "&:hover": {
    transform: "scale(1.02)",
    opacity: 0.95,
  },
})

export default Team
